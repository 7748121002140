<template>
  <div class="shop-selection-list-page">
    <!-- [筛选项] -->
    <kr-card :padding="[0, 0, 12, 0]" @rect:update="onTopCardRectUpdate">
      <a-form layout="inline" :form="queryParams">
        <div class="filter-condition-form">
          <a-form-item label="序号：">
            <a-input
              v-model.trim="queryParams.id"
              allowClear
              placeholder="请输入序号"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="名称：">
            <a-input
              v-model.trim="queryParams.item_name"
              allowClear
              placeholder="请输入商品名称"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="商品ID：" class="shop-id-item">
            <a-input
              v-model.trim="queryParams.item_id"
              allowClear
              placeholder="请输入商品ID"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <!--  <a-form-item label="直播：">
             <a-date-picker
               style="width: 180px"
               type="datetime"
               v-model="queryParams.live_date"
               format="YYYY-MM-DD"
               valueFormat="YYYY-MM-DD"
               placeholder="选择直播日期"
             />
           </a-form-item>
           <a-form-item label="主播：">
             <a-input
               v-model.trim="queryParams.live_user"
               allowClear
               placeholder="请输入主播昵称"
               style="width: 180px"
               @keypress.enter.native="reload"
             />
           </a-form-item>-->
          <a-form-item label="状态：">
            <a-select
              v-model="queryParams.check_status"
              placeholder="审核状态"
              allowClear
              style="width: 180px"
            >
              <template v-for="(item, key) in checkStatusAllOptions">
                <a-select-option v-if="item.value !== 0" :key="key" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </template>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              :loading="listLoading"
              style="margin-right: 12px"
              @click="reload"
              >查询</a-button
            >
            <a-button :loading="listLoading" @click="reset">重置</a-button>
          </a-form-item>
        </div>
      </a-form>
    </kr-card>
    <!-- [列表] -->
    <a-table
      class="tablecl"
      :class="list.length * 56 < tableScrollY ? 'less-table' : ''"
      :scroll="{ x: 1000, y: tableScrollY }"
      :rowKey="record => record.id"
      :columns="goodsColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无商品' }"
      @change="paginationHandler"
    >
      <template slot="goods_img" slot-scope="record">
        <my-image
          :img-width="40"
          style="margin: 0px auto"
          v-if="record.item_id__pict_url || record.item_id__slide_images[0]"
          :imgUrl="record.item_id__pict_url || record.item_id__slide_images[0]"
        />
      </template>
      <template slot="item_id__commission_rate" slot-scope="record">
        {{
          record.item_id__commission_rate || record.item_id__commission_rate === 0
            ? record.item_id__commission_rate / 100 + '%'
            : '--'
        }}
      </template>
      <template slot="item_id__exclusive_commission_rate" slot-scope="record">
        {{
          record.item_id__exclusive_commission_rate ||
          record.item_id__exclusive_commission_rate === 0
            ? record.item_id__exclusive_commission_rate / 100 + '%'
            : '--'
        }}
      </template>
      <template slot="item_id__colonel_commission_rate" slot-scope="record">
        {{
          record.item_id__colonel_commission_rate || record.item_id__colonel_commission_rate === 0
            ? record.item_id__colonel_commission_rate / 100 + '%'
            : '--'
        }}
      </template>
      <template slot="is_recycle" slot-scope="record">
        <a-tag v-if="record.is_recycle === 0" color="green">否</a-tag>
        <a-tag v-if="record.is_recycle === 1" color="red">是</a-tag>
      </template>
      <template slot="check_status" slot-scope="record">
        <div
          :style="{
            color:
              record.check_status === 3 || record.check_status === 7
                ? 'red'
                : record.check_status === 5
                ? 'green'
                : '',
          }"
        >
          {{
            checkStatusAllOptions.filter(item => item.value === record.check_status).length > 0
              ? checkStatusAllOptions.filter(item => item.value === record.check_status)[0].label
              : ''
          }}
        </div>
      </template>
      <template slot="share_link" slot-scope="record">
        <div class="share-link" v-if="record.share_link">
          <span>已上传</span>
          <a-icon class="copy-icon" type="copy" @click="copyClick(record.share_link)" />
        </div>
        <span v-else> 待上传 </span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button
          v-if="
            record.check_status === 0 ||
            record.check_status === 4 ||
            record.check_status === 8 ||
            record.check_status === 1
          "
          type="primary"
          size="small"
          @click="handleApprovalGoods(record)"
          >审核</a-button
        >
        <a-button
          class="look"
          v-else
          type="primary"
          size="small"
          @click="handleApprovalGoods(record)"
          >查看</a-button
        >
      </template>
    </a-table>
    <div v-if="createGoodsDrawerVisiable">
      <a-drawer
        placement="right"
        width="800"
        @close="closeClick"
        :visible="createGoodsDrawerVisiable"
      >
        <template slot="title">
          <div class="drawer-title-div">
            选品记录<span class="drawer-title">{{
              checkStatusAllOptions.filter(item => item.value === goodsRow.check_status).length > 0
                ? checkStatusAllOptions.filter(item => item.value === goodsRow.check_status)[0]
                    .label
                : ''
            }}</span>
          </div>
        </template>
        <audit-detail
          :step="
            goodsRow.check_status === 0 ||
            goodsRow.check_status === 2 ||
            goodsRow.check_status === 3 ||
            goodsRow.check_status === 4
              ? 2
              : 3
          "
          :readOnly="
            goodsRow.check_status === 0 ||
            goodsRow.check_status === 4 ||
            goodsRow.check_status === 8 ||
            goodsRow.check_status === 1
              ? false
              : true
          "
          v-on:closeClick="closeClick"
          v-on:reload="reload"
          :detailData="goodsRow"
        />
      </a-drawer>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import auditDetail from '@/pages/admin/merchant/dialog/auditDetail';
import { checkStatusAllOptions } from '@/pages/admin/merchant/use';
import { getSelectionGoodsList } from '@/service/merchant';

export default defineComponent({
  name: 'KrSelectionRecord',
  components: {
    auditDetail,
  },
  methods: {
    copyClick(share_link) {
      var element = this.createElement(share_link);
      element.select();
      element.setSelectionRange(0, element.value.length);
      document.execCommand('copy');
      element.remove();
      this.$message.success('复制精选链接成功！');
    },
    //创建临时的输入框元素
    createElement(text) {
      var isRTL = document.documentElement.getAttribute('dir') === 'rtl';
      var element = document.createElement('textarea');
      // 防止在ios中产生缩放效果
      element.style.fontSize = '12pt';
      // 重置盒模型
      element.style.border = '0';
      element.style.padding = '0';
      element.style.margin = '0';
      // 将元素移到屏幕外
      element.style.position = 'absolute';
      element.style[isRTL ? 'right' : 'left'] = '-9999px';
      // 移动元素到页面底部
      let yPosition = window.pageYOffset || document.documentElement.scrollTop;
      element.style.top = `${yPosition}px`;
      //设置元素只读
      element.setAttribute('readonly', '');
      element.value = text;
      document.body.appendChild(element);
      return element;
    },
    paginationHandler(params) {
      if (params.pageSize !== this.queryParams.page_size) {
        this.queryParams.page = 1;
        this.pagination.current = 1;
        this.queryParams.page_size = params.pageSize;
      } else {
        this.pagination.current = params.current;
        this.queryParams.page = params.current;
      }
      this.reload(false);
    },
  },
  setup() {
    const createGoodsDrawerVisiable = ref(false);
    const goodsRow = ref({});
    const handleApprovalGoods = row => {
      createGoodsDrawerVisiable.value = true;
      goodsRow.value = row;
    };
    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY = ref(windowHeight - 280);
    const onTopCardRectUpdate = rect => {
      tableScrollY.value = windowHeight - 190 - rect.height;
    };
    // 商品列表
    // 商品列表
    const goodsColumns = [
      {
        title: '序号',
        width: 100,
        align: 'left',
        dataIndex: 'id',
      },
      {
        title: '图片',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'goods_img' },
      },
      {
        title: '商品ID',
        dataIndex: 'item_id',
        align: 'left',
        ellipsis: true,
        width: 190,
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '店铺名称',
        dataIndex: 'shop_name',
        width: 200,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },
      /*  {
          title: '店铺类型',
          dataIndex: 'brand_type',
          width: 100,
          ellipsis: true,
          align: 'center',
          customRender: text => {
            return text === 1 ? '品牌方' : text === 2 ? '代理商' : text === 3 ? '黑标' : '--';
          },
        },
        {
          title: '一级分类',
          dataIndex: 'merchant_category',
          width: 120,
          ellipsis: true,
          align: 'center',
          customRender: text => {
            return text || '--';
          },
        },*/
      {
        title: '商品名称',
        dataIndex: 'item_name',
        width: 200,
        ellipsis: true,
        align: 'left',
      },

      /* {
        title: '手卡',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'card_img' },
      },
      {
        title: '比价图',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'price_img' },
      },
      {
        title: '其它资料',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'other_img' },
      },*/
      {
        title: '销售价',
        dataIndex: 'kuran_price',
        align: 'right',
        ellipsis: true,
        width: 100,
        customRender: (text, record) => {
          let price = record?.kuran_price;
          if (price !== null && price !== undefined && price !== '') {
            price = '￥' + price;
          } else {
            price = '--';
          }
          return price;
        },
      },
      /* {
        title: '公开佣金',
        scopedSlots: { customRender: 'item_id__commission_rate' },
        width: 100,
        align: 'right',
      },*/
      {
        title: '达人佣金',
        scopedSlots: { customRender: 'item_id__exclusive_commission_rate' },
        width: 100,
        align: 'right',
      },
      {
        title: '团长佣金',
        scopedSlots: { customRender: 'item_id__colonel_commission_rate' },
        width: 100,
        align: 'right',
      },
      {
        title: '价格/机制',
        dataIndex: 'description',
        align: 'left',
        width: 100,
        ellipsis: true,
        customRender: text => {
          return text || '--';
        },
      },
      /* {
        title: '赠品工具',
        dataIndex: 'giveaway_tool',
        align: 'center',
        width: 100,
        customRender: text => {
          return text === 1 ? '已开启' : '未开启';
        },
      },

      {
        title: '赠品价格/机制',
        dataIndex: 'giveaway_description',
        align: 'left',
        width: 130,
        ellipsis: true,
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '多拍链接',
        dataIndex: 'is_minimum_per_order',
        align: 'center',
        width: 100,
        ellipsis: true,
        customRender: text => {
          return text === 1 ? '已设置' : '未设置';
        },
      },
      {
        title: '排期',
        dataIndex: 'live_date',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '主播',
        dataIndex: 'live_user',
        width: 100,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '库存',
        dataIndex: 'stock',
        width: 100,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },

      {
        title: '店铺口碑分',
        dataIndex: 'item_id__merchant_experience_score',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '店铺体验分',
        dataIndex: 'item_id__service_score',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '发货周期',
        dataIndex: 'delivery_cycle',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '运费险',
        dataIndex: 'freight_policy',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text === 1 ? '有' : '无';
        },
      },*/
      {
        title: '对接招商',
        dataIndex: 'contact__username',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      /* {
        title: '公司',
        dataIndex: 'supplier',
        width: 200,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },*/
      {
        title: '状态',
        scopedSlots: { customRender: 'check_status' },
        align: 'center',
        width: 110,
      },
      {
        title: '到样日期',
        dataIndex: 'sample_date',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      /*  {
        title: '精选链接',
        scopedSlots: { customRender: 'share_link' },
        width: 120,
        ellipsis: true,
        align: 'center',
      },

      {
        title: '是否退样',
        scopedSlots: { customRender: 'is_recycle' },
        width: 100,
        align: 'center',
      },*/
      {
        title: '操作',
        align: 'left',
        fixed: 'right',
        width: 100,
        scopedSlots: { customRender: 'operation' },
      },
    ];
    const list = ref([]);
    const pagination = ref({
      size: 'small',
      current: 1,
      pageSize: 20,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      showTotal: total =>
        `共 ${total} 条记录 第 ${pagination.value.current}/${Math.ceil(
          total / pagination.value.pageSize,
        )} 页`,
      showQuickJumper: true,
      showSizeChanger: true,
      onShowSizeChange: (current, pageSize) => (pagination.value.pageSize = pageSize),
    });

    const queryParams = ref({
      page: 1,
      page_size: 20,
      item_name: '',
      check_status: undefined,
      id: '',
      item_id: '',
      live_date: undefined,
      live_user: undefined,
    });

    const listLoading = ref(false);
    const getGoodsList = async () => {
      listLoading.value = true;
      const { err, res } = await getSelectionGoodsList(queryParams.value);
      listLoading.value = false;
      if (!err) {
        if (res.success) {
          const one_pagination = { ...pagination.value };
          list.value = res.data.results;
          one_pagination.total = res.data.count;
          pagination.value = one_pagination;
        }
      }
    };

    const reload = async (clean = true) => {
      if (clean) {
        pagination.value.current = 1;
        queryParams.value.page = 1;
      }

      await getGoodsList();
    };

    const reset = async () => {
      queryParams.value = {
        page: 1,
        item_name: '',
        check_status: undefined,
        item_id: '',
      };
      pagination.value.page = 1;
      await reload();
    };

    const closeClick = () => {
      createGoodsDrawerVisiable.value = false;
    };
    return {
      checkStatusAllOptions,
      closeClick,
      onTopCardRectUpdate,
      goodsRow,
      handleApprovalGoods,
      createGoodsDrawerVisiable,
      pagination,
      listLoading,
      goodsColumns,
      tableScrollY,
      list,
      queryParams,
      reset,
      reload,
    };
  },
  created() {
    this.reload();
  },
});
</script>

<style lang="less">
.drawer-title-div {
  display: flex;
  align-items: center;
  .drawer-title {
    display: inline-block;
    color: #e83741;
    border: 1px solid #e83741;
    //width: 60px;
    padding: 0 12px;
    height: 24px;
    line-height: 22px;
    border-radius: 12px;
    margin-left: 20px;
    text-align: center;
    font-size: 12px;
  }
}

.shop-selection-list-page {
  display: flex;
  flex-direction: column;
  .share-link {
    display: flex;
    justify-content: center;
    .copy-icon {
      margin-left: 4px;
      padding-top: 3px;
      font-size: 15px;
      color: #999999;
      &:hover {
        color: #00aaff;
      }
    }
  }
  .mr-10 {
    margin-right: 10px;
  }
  .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  .ant-table-tbody > tr,
  .ant-table-tbody > tr > td {
    height: 56px !important;
  }
  /deep/ .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  /deep/.ant-table-tbody > tr {
    height: 56px !important;
  }
  /deep/.ant-table-tbody > tr > td {
    height: 56px !important;
  }
}
</style>

<style lang="less" scoped>
/deep/ .ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}
.ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}
</style>
